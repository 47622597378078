export const PROJECTS_STORE = [
  {
    name: 'New Hope', link: 'https://newsurrogacy.com/',
    desc: 'New Hope - International Surrogacy Agency in Ukraine', img: 'case_newhope.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'BEM', 'Angular', 'TypeScript', 'JS', 'PHP', 'Animation CSS', 'Animation JS', 'Git', 'Figma'],
    rating: 9, year: '2021'
  },
  {
    name: 'The Lucky Charm', link: 'https://charm.justtwic.art',
    desc: 'The Lucky Charm (TLC) is a newsagency franchise that specialises in stationery, printer consumables, greeting cards, gifts, magazines and lottery products.',
    img: 'case-charm.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'BEM', 'JS', 'Animation CSS', 'Animation JS', 'Git', 'XD'],
    rating: 8, year: '2021'
  },
  {
    name: 'Vero',
    link: 'https://myvero.com/',
    desc: 'Vero - insurance service, save over 50% of your time, increase your profits, and leverage technology to take your insurance agency to the next level.',
    img: 'case-vero.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'BEM', 'Angular', 'Ng-Bootstrap', 'TypeScript', 'JS', 'Animation CSS', 'Animation JS', 'Git', 'Figma'],
    rating: 10,
    year: '2020'
  },
  {
    name: 'UNI-Prep Institute', link: 'https://www.uni-prep.com/', link2: 'https://uniprep.justtwic.art/ ',
    desc: 'UNI-Prep is a world leader in TESOL & TEFL, Business & Management Courses', img: 'case-uniprep.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'BEM', 'PHP', 'Bootstrap', 'JS', 'Gulp', 'Git', 'Figma'],
    rating: 8, year: '2021'
  },
  {
    name: 'AgileBear', link: 'https://agile-bear.justtwic.art', link2: 'https://agilebear.com/',
    desc: 'AgileBear- Agile Consulting and Certified Scrum Training', img: 'case-agile-bear.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'Angular', 'TypeScript', 'JS', 'Git', 'PSD', 'Zeplin'],
    rating: 8, year: '2021'
  },
  {
    name: 'Hipo', link: 'https://wearehipo.com/', link2: 'https://hipo.justtwic.art/',
    desc: 'The Hiring Portal', img: 'case-hipo.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'JS', 'Gulp', 'Git', 'PSD'],
    rating: 6, year: '2021'
  },
  {
    name: 'Sharefy',
    link: 'https://www.figma.com/proto/RGJOAiuzykpTUMcKkYz7sR/TheBrooklyn?page-id=0%3A420&node-id=745%3A1685&scaling=min-zoom',
    desc: 'Sharefy - Landing page app design that allows you to share music between music apps',
    img: 'case-sharefy.jpg',
    tags: ['Figma'],
    rating: 6,
    year: '2021'
  },
  {
    name: 'MICS', link: 'https://mic.justtwic.art/', link2: 'https://events.fastcompany.com/mic-summit-2021',
    desc: 'We are pleased to announce the first-ever Most Innovative Companies Summit.', img: 'case-mic.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'JS', 'Gulp', 'Git', 'XD'],
    rating: 6, year: '2021'
  },
  {
    name: 'Hager', link: 'https://www.hagerdaniel.at',
    desc: 'Director for Commercial and Musicvideo productions', img: 'case-hager.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'BEM', 'Angular', 'TypeScript', 'JS', 'JSON', 'Git', 'XD'],
    rating: 7, year: '2021'
  },
  {
    name: 'Genoox',
    link: 'https://genoox.justtwic.art',
    desc: 'Genoox’ Franklin provides comprehensive work tools and interpretation data for analysing variants cases',
    img: 'case-genoox.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'JS', 'Gulp', 'Git', 'PSD', 'Zeplin'],
    rating: 7,
    year: '2021'
  },
  {
    name: 'Home Pro Launchpad', link: 'https://homeprolaunchpad.com/',
    desc: 'Home Pro Launchpad aims to streamline your marketing by offering you a one-stop shop for your single property website, listing video, flyers, social media posts, postcards, and more.',
    img: 'case-homeprolaunchpad.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'BEM', 'Vue', 'Nuxt', 'JS', 'Git', 'PSD'],
    rating: 8, year: '2021'
  },
  {
    name: 'Adalysis', link: 'https://adalysis.justtwic.art',
    desc: 'Accelerate your PPC growth the smarter way', img: 'case-adalysis.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'JS', 'Gulp', 'Git', 'PSD', 'Zeplin'],
    rating: 7, year: '2020'
  },
  {
    name: 'Loanin Click', link: 'https://loaninclick.justtwic.art',
    desc: 'Banking Application loaninClick ', img: 'case-loanick.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'JS', 'Gulp', 'Git', 'PSD', 'Zeplin'],
    rating: 6, year: '2020'
  },
  {
    name: 'UCash',
    link: 'http://ucash.justtwic.art/',
    desc: 'A small online casino site, with chats and. exchange of game currency',
    img: 'case-ucash.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'JS', 'Gulp', 'Git', 'PSD', 'Zeplin'],
    rating: 6,
    year: '2020'
  },
  {
    name: 'MagicShop',
    link: 'https://www.figma.com/proto/OooAsRj76Nr77Q63ER417H/MagicShop?page-id=2%3A0&node-id=2%3A3&scaling=min-zoom',
    desc: 'Development of the design of the store of esoteric things',
    img: 'case-magic.jpg',
    tags: ['Figam'],
    rating: 7,
    year: '2020'
  },
  {
    name: 'Grab', link: 'http://grab.justtwic.art',
    desc: 'Service for collecting data on the movement of people', img: 'case-grab.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'BEM', 'Angular', 'Ng-Bootstrap', 'TypeScript', 'JS', 'Git', 'PSD', 'Zeplin'],
    rating: 7, year: '2020'
  },
  {
    name: 'Shelfit', link: 'https://shelfit.tech',
    desc: 'The Most Efficient Way to Organize Your Inventory', img: 'case-shelfit.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'JS', 'PHP', 'Gulp', 'Git'],
    rating: 7, year: '2020'
  },
  {
    name: 'Lu Assistant', link: 'https://lu.justtwic.art',
    desc: 'Improve and achieve more with your personal assistant Lu', img: 'case-lu.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'JS', 'Gulp', 'Git', 'PSD', 'Zeplin'],
    rating: 7, year: '2019'
  },
  {
    name: 'Hotel Terme Venezia', link: 'https://www.termevenezia.it/en/',
    desc: 'Simple page for Hotel Terme Venezia', img: 'case-termevenezia.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'JS', 'Gulp', 'Git', 'Figma'],
    rating: 6, year: '2019'
  },
  {
    name: 'SberCloud',
    link: 'https://sbercloud.ru',
    desc: 'SberCloud (Cloud Technologies LLC) is a cloud provider of services and services by IaaS and PaaS models (IT infrastructure for rent) for individuals, startups, small, medium, large businesses, government agencies and corporations.',
    img: 'case-sbercloud.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'BEM', 'Vue', 'Nuxt', 'JS', 'Git', 'PSD'],
    rating: 9,
    year: '2019'
  },
  {
    name: 'Muslim Tour', link: 'https://muslim-tour.justtwic.art', link2: 'https://muslim-group.ru/',
    desc: 'Muslim Tour - pilgrimage to Mecca', img: 'case-muslim-tour.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'BEM', 'Bootstrap', 'JS', 'jQuery', 'Gulp', 'Git', 'Zeplin', 'Sketch'],
    rating: 7, year: '2019'
  },
  {
    name: 'Constant Energy Capital',
    link: 'https://constant-energy.justtwic.art',
    link2: 'https:/constantenergy.com',
    desc: 'With Constant Energy Capital, you can get back up power today, and pay over time',
    img: 'case-energy-capital.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'BEM', 'JS', 'jQuery', 'Gulp', 'Git', 'InVisionStudio'],
    rating: 6,
    year: '2019'
  },
  {
    name: 'GdeBar',
    link: 'https://concep.to',
    desc: 'GdeBar.Ru is a unique Internet project created in order to provide all kinds of help and support in finding and choosing a bar, restaurant, cafe or any other place of rest for a truly pleasant and comfortable time.',
    img: 'case-concepto.jpg',
    tags: ['HTML', 'CSS3', 'SCSS', 'Bootstrap', 'JS', 'Vue', 'jQuery', 'PHP', 'Laravel', 'Webpack', 'Git', 'PSD', 'Zeplin'],
    rating: 6,
    year: '2018'
  },
  {
    name: 'North American Nutra', link: 'https://selenex.justtwic.art', link2: 'northamericannutra.com',
    desc: 'North American Nutra Landing Pages', img: 'case-selenex.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'BEM', 'Bootstrap', 'JS', 'jQuery', 'Gulp', 'Git', 'PSD'],
    rating: 6, year: '2018'
  },
  {
    name: 'Plus karma', link: 'karma.justtwic.art', link2: 'https://pluskarma.ru/',
    desc: '+ Karma: Growing Online Reputation', img: 'case-pluskarma.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'BEM', 'Bootstrap', 'JS', 'jQuery', 'Gulp', 'Git', 'XD'],
    rating: 5, year: '2018'
  },
  {
    name: 'Mark Deturk', link: 'https://mark-deturk.justtwic.art',
    desc: 'Marc De Turck Conference', img: 'case-mark.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'BEM', 'Bootstrap', 'JS', 'jQuery', 'Gulp', 'Git', 'XD'],
    rating: 7, year: '2018'
  },
  {
    name: 'Vdele', link: 'https://vdele.justtwic.art/',
    desc: 'Social network vdele.ru', img: 'case-vdele.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'Angular', 'JS', 'jQuery', 'Gulp', 'Git', 'PSD', 'Zeplin'],
    rating: 7, year: '2017'
  },
  {
    name: 'Concepto', link: 'https://www.gdebar.ru/',
    desc: 'Digital Agency', img: 'case-gdebar.jpg',
    tags: ['HTML5', 'CSS3', 'SCSS', 'JS', 'jQuery', 'Gulp', 'Git', 'PSD', 'Zeplin'],
    rating: 6, year: '2017'
  },
  {
    name: 'Mail.CZ', link: 'https://my.mail.cz',
    desc: 'mail.cz - email service', img: 'case-mailcz.jpg',
    tags: ['HTML', 'CSS', 'SCSS', 'BEM', 'AngularJS', 'Bootstrap', 'JS', 'Animation CSS', 'Animation JS', 'Git', 'Skatch', 'Zeplin'],
    rating: 7, year: '2017'
  },
  {
    name: 'Mainbox', link: 'https://mainbox.com',
    desc: 'Mainbox assistant in the world of overseas shopping', img: 'case-mainbox.jpg',
    tags: ['HTML', 'CSS', 'SCSS', 'BEM', 'AngularJS', 'Bootstrap', 'JS', 'Animation CSS', 'Animation JS', 'Git', 'Skatch', 'Zeplin'],
    rating: 8, year: '2017'
  },
  {
    name: 'Statement Market',
    link: 'https://statementmarket.com',
    desc: 'A marketing statement tells the reader the "who, what and how" of your company`s plan to achieve its mission.',
    img: 'case-statementmarket.jpg',
    tags: ['HTML', 'SLIM', 'CSS3', 'SCSS', 'BEM', 'AngularJS', 'JS', 'Ruby On Rails', 'Gulp', 'Git', 'PSD', 'Zeplin'],
    rating: 7,
    year: '2016'
  },
  {
    name: 'MetaGate',
    link: 'https://metagate.justtwic.art',
    desc: 'Promo page for the service for purchases through cryptocurrencies # MetaHash, ETH, BTC and more.',
    img: 'case-metagate.jpg',
    tags: ['HTML', 'Pag', 'CSS3', 'SCSS', 'BEM', 'JS', 'Gulp', 'Git', 'PSD', 'Zeplin'],
    rating: 5,
    year: '2016'
  },
  {
    name: 'Omoe',
    link: 'https://omoe.com.ua',
    desc: 'Omoe is a dynamically developing online store of footwear, clothing, accessories and household goods.',
    img: 'case-omoe.jpg',
    tags: ['HTML', 'CSS3', 'SCSS', 'BEM', 'PSD', 'Zeplin'],
    rating: 6,
    year: '2016'
  },
  {
    name: 'UniRecruit',
    link: 'http://unirecruit.com.ua',
    desc: 'UniRecruit is the first and most non-trivial recruiting agency on the Ukrainian labor market',
    img: 'case-unirecruit.jpg',
    tags: ['HTML', 'CSS', 'SCSS', 'BEM', 'JS', 'Animation CSS', 'Git', 'Skatch', 'Zeplin'],
    rating: 6,
    year: '2016'
  },
  {
    name: 'OЧКИ31.РФ',
    link: 'https://очки31.рф',
    desc: 'The task was to develop the UX and UI design for an online store glasses, the work carried out for a couple of days',
    img: 'case-glasses.jpg',
    tags: ['PSD', 'Zeplin'],
    rating: 7,
    year: '2016'
  },
  {
    name: 'Qquest Top',
    link: 'https://quest-top.com.ua/',
    desc: 'A web studio with big ambitions',
    img: 'case-quest-top.jpg',
    tags: ['HTML', 'CSS', 'SCSS', 'PSD', 'Zeplin'],
    rating: 6,
    year: '2016'
  },
];
